import { withRouter } from "next/router"
import { FooterNavigationClick } from "../../../utils/gtm"

function FooterLink({ children, href, router, className, id, removeUrl }) {
  (function prefetchPages() {
    if (typeof window !== "undefined") {
      router.prefetch(router.pathname)
    }
  })()

  const handleClick = e => {
    e.preventDefault()
    FooterNavigationClick(href)
    router.push(href)
  }

  const isCurrentPath =
    router.pathname === href ||
    router.asPath === href ||
    router.pathname.includes(href) ||
    router.asPath.includes(href)

  return removeUrl ? (
    <button
      type="button"
      id={id}
      className={`${className} uppercase text-xs py-2 inline-block text-white lg:text-right`}
    >
      {children}
    </button>
  ) : (
    <a
      href={href}
      id={id}
      className={`${className} uppercase text-xs py-2 block ${
        isCurrentPath ? "text-yellow-500" : "text-white"
      }`}
      onClick={handleClick}
    >
      {children}
    </a>
  )
}
export default withRouter(FooterLink)
